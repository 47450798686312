import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

// let couponContainer = document.querySelectorAll('.discount-container')


  
// couponContainer.forEach(container => {
//     container.addEventListener('click', getContent);
// })

// function getContent(e) {
//     const couponValue = e.target.textContent
//     navigator.clipboard.writeText(couponValue).then(() => {      
//     })
// }

    
    


const Discount = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)
  console.log(edges)


  let results = edges.map((e, index) => {
    
    return(
        <div className="discount-container" data-coupon={e.node.data.code}>

            <div className="discount-brand">
                <h2>{e.node.data.brand}</h2>
            </div>
            <div className="discount-content">
                <h3>{e.node.data.date}</h3>
                <p>{e.node.data.description}</p>
            </div>
            <div className="discount-code">
                <p>{e.node.data.code}</p>                            
            </div>
        </div>
    )

    

    })

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.collectivedress.com/maxi-dresses" />
        <title>Collective Dresses Discount Page</title>
        <meta name="description" content="Using discount codes when shopping for dresses can provide several benefits for shoppers. It allows them to save money on their purchase. By applying a discount code at the checkout, shoppers can enjoy significant reductions in the overall cost of the dress. This can be particularly advantageous for those shopping on a budget or looking to stretch their clothing budget further. The money saved can then be allocated towards other expenses or used to purchase additional items."/>
      </Helmet>

      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 className="text-2xl mt-5 mb-5 font-bold">
          Latest Discount Code
        </h1>
        <p className="hidden md:block">
        Using discount codes when shopping for dresses can provide several benefits for shoppers. It allows them to save money on their purchase. By applying a discount code at the checkout, shoppers can enjoy significant reductions in the overall cost of the dress. This can be particularly advantageous for those shopping on a budget or looking to stretch their clothing budget further. The money saved can then be allocated towards other expenses or used to purchase additional items. 

            Discount codes also provide an opportunity for shoppers to access higher-end or designer dresses that may have been previously out of their price range. With the application of a discount code, these luxury dresses become more affordable and attainable. Shoppers can enjoy the satisfaction of owning a high-quality, fashionable dress without breaking the bank. Moreover, the use of discount codes can empower individuals to explore different retailers and brands that they may not have considered before, expanding their options and enabling them to discover new styles they love. Overall, using discount codes when shopping for dresses offers financial savings and the opportunity to access a wider range of fashion choices, making it a beneficial option for shoppers.
        </p>
        <p className="text-l mt-5 mb-5 font-bold">          
          Collective Dress introduces a variety of new dress styles every Friday. This provides a fresh selection of fashionable items for customers to browse through. Be sure to check in each week for unique updates to your wardrobe.
        </p>

        {results}
      </div>
      

      {/* <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} />
      </div> */}
      <p className="lg:hidden"> Using discount codes when shopping for dresses can provide several benefits for shoppers. It allows them to save money on their purchase. By applying a discount code at the checkout, shoppers can enjoy significant reductions in the overall cost of the dress. This can be particularly advantageous for those shopping on a budget or looking to stretch their clothing budget further. The money saved can then be allocated towards other expenses or used to purchase additional items. 

Discount codes also provide an opportunity for shoppers to access higher-end or designer dresses that may have been previously out of their price range. With the application of a discount code, these luxury dresses become more affordable and attainable. Shoppers can enjoy the satisfaction of owning a high-quality, fashionable dress without breaking the bank. Moreover, the use of discount codes can empower individuals to explore different retailers and brands that they may not have considered before, expanding their options and enabling them to discover new styles they love. Overall, using discount codes when shopping for dresses offers financial savings and the opportunity to access a wider range of fashion choices, making it a beneficial option for shoppers.</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "discount_page" } }) {
      edges {
        node {
          data {
            brand
            description
            code
            date
          }
        }
      }
    }
  }
`

export default Discount
